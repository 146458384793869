import Amplify, { Auth, API, graphqlOperation, Storage } from "aws-amplify"
import {
  Authenticator,
  SignIn,
  ConfirmSignUp,
  Greetings,
  SignUp,
  ForgotPassword,
} from "aws-amplify-react"
import CustomSignUp from "../../../../components/CustomSignUp"
import {
  createProject,
  updateProject,
  createRoom,
} from "../../../../graphql/mutations"

import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"
import awsExports from "../../../../aws-exports"
import { Hub } from "aws-amplify"
import { listMainTags } from "../../../../graphql/queries"
import {
  createMainTag,
  createTag,
  deleteMainTag,
  deleteTag,
} from "../../../../graphql/mutations"
import AdminMenu from "../../../../components/AdminMenu"

Amplify.configure(awsExports)

const SignupLink = props => (
  <div>
    {/* {props.authState} */}
    {props.authState === "signIn" && (
      <div>
        <button onClick={() => props.onStateChange("signUp")}>
          Sign Up here
        </button>
      </div>
    )}
  </div>
)

const TagsEditorPage = () => {
  const [user, setUser] = useState(null)
  const [userPool, setUserPool] = useState(null)

  useEffect(() => {
    if (!user) {
      getUser()
    }
  }, [])

  async function getUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      console.log("user: ", user)
      //check for user pool group
      console.log(
        "User Pool Group: ",
        user.signInUserSession.accessToken.payload["cognito:groups"]
      )
      // console.log("attributes: ", user.attributes)
      console.log(
        "Is Admin: ",
        user.signInUserSession.accessToken.payload["cognito:groups"].includes(
          "Admins"
        )
      )
      setUser(user)
    } catch (err) {
      console.log("Error: ", err)
    }
  }

  Hub.listen("auth", data => {
    switch (data.payload.event) {
      case "signIn":
        console.log("user signed in")
        getUser()
        break
      case "signUp":
        console.log("user signed up")
        break
      case "signOut":
        console.log("user signed out")
        break
      case "signIn_failure":
        console.log("user sign in failed")
        break
      case "configured":
        console.log("the Auth module is configured")
    }
  })

  function handleAuthStateChange(state) {
    if (state === "signedIn") {
      console.log("running handle auth change")
      getUser()
    }
  }

  const AddTagComponent = props => {
    const initialState = {
      name: "",
      mainTagID: "",
    }
    const [formState, setFormState] = useState(initialState)

    async function addTag() {
      try {
        // if (!formState.name || !formState.description) return
        // setUserDetails()
        const tag = { ...formState }
        tag.mainTagID = props.mainTag
        console.log("tag: ", tag)
        // settags([...tags, tag])
        const submittedTag = await API.graphql(
          graphqlOperation(createTag, { input: tag })
        )
        setFormState(initialState)
        props.refresh()
        // console.log("submitted tag: ", submittedtag)
      } catch (err) {
        console.log("error creating tag:", err)
      }
      // try {

      //   const submittedRooms = await API.graphql(graphqlOperation(createRooms, {input: }))
      // }
    }

    function setInput(key, value) {
      setFormState({ ...formState, [key]: value })
      console.log("key: ", key)
      console.log("value: ", value)
      const project = { ...formState }
      console.log("project: ", project)
    }

    return (
      <div>
        <input
          onChange={event => setInput("name", event.target.value)}
          value={formState.name}
          placeholder="Enter a new Tag"
        />
        <button onClick={() => addTag()}>Add a new Tag</button>
      </div>
    )
  }

  const MainTagList = props => {
    const initialState = {
      name: "",
    }

    const [mainTags, setMainTags] = useState([])
    const [formState, setFormState] = useState(initialState)

    useEffect(() => {
      fetchMainTags()
    }, [])

    async function addMainTag() {
      try {
        // if (!formState.name || !formState.description) return
        // setUserDetails()
        const mainTag = { ...formState }

        console.log("mainTag: ", mainTag)
        // setmainTags([...mainTags, mainTag])
        const submittedMainTag = await API.graphql(
          graphqlOperation(createMainTag, { input: mainTag })
        )
        setFormState(initialState)
        fetchMainTags()
        // console.log("submitted mainTag: ", submittedmainTag)
      } catch (err) {
        console.log("error creating mainTag:", err)
      }
      // try {

      //   const submittedRooms = await API.graphql(graphqlOperation(createRooms, {input: }))
      // }
    }

    function setInput(key, value) {
      setFormState({ ...formState, [key]: value })
      console.log("key: ", key)
      console.log("value: ", value)
      const project = { ...formState }
      console.log("project: ", project)
    }

    async function fetchMainTags() {
      try {
        const mainTagsData = await API.graphql(graphqlOperation(listMainTags))
        console.log("main tags data: ", mainTagsData)
        const mainTags = mainTagsData.data.listMainTags.items
        setMainTags(mainTags)
        console.log("mainTags: ", mainTags)
      } catch (err) {
        console.log("error fetching mainTags: ", err)
      }
    }

    async function deleteThisTag(inputTag) {
      console.log("tag: ", inputTag)
      if (inputTag.projects.items.length > 0) {
        alert(
          "Please remove this tag from all projects first before deleting it"
        )
      } else {
        try {
          const tag = { id: inputTag.id }
          console.log("tag: ", tag)
          const deletedTag = await API.graphql(
            graphqlOperation(deleteTag, { input: tag })
          )
          console.log("deleted tag: ", deletedTag)
          // const newtagState = tags.filter(tag => tag.)
          fetchMainTags()
        } catch (err) {
          console.log("error deleting tag:", err)
        }
      }
    }

    async function deleteThisMainTag(mainTagInput) {
      if (mainTagInput.tags.items.length !== 0) {
        alert("All Tags for this Main Tag must be deleted first!")
      } else {
        try {
          const mainTag = { id: mainTagInput.id }
          console.log("mainTag: ", mainTag)
          const deletedMainTag = await API.graphql(
            graphqlOperation(deleteMainTag, { input: mainTag })
          )
          console.log("deleted mainTag: ", deletedMainTag)
          // const newmainTagState = mainTags.filter(mainTag => mainTag.)
          fetchMainTags()
        } catch (err) {
          console.log("error deleting mainTag:", err)
        }
      }
    }

    // console.log("auth-state: ", props.authState)
    // console.log("auth-data: ", props.authData)
    if (
      props.authState === "signedIn" &&
      user &&
      (user.signInUserSession.accessToken.payload["cognito:groups"].includes(
        "Admins"
      ) ||
        user.signInUserSession.accessToken.payload["cognito:groups"].includes(
          "Editors"
        ))
    ) {
      if (mainTags.length > 0) {
        return (
          <div>
            <AdminMenu />
            <section>
              {mainTags.map((mainTag, index) => (
                <div
                  key={mainTag.id ? mainTag.id : index}
                  style={{
                    border: "1px solid black",
                    margin: "1rem",
                  }}
                >
                  <div>
                    <h3 key="title">{mainTag.name}</h3>
                    <div>
                      <button onClick={() => deleteThisMainTag(mainTag)}>
                        Delete Main Tag
                      </button>
                    </div>
                  </div>
                  <ul>
                    {mainTag.tags.items &&
                      mainTag.tags.items.map(tag => (
                        <li key={tag.name}>
                          <div>
                            {tag.name}
                            <div>
                              <button onClick={() => deleteThisTag(tag)}>
                                Delete Tag
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                  <AddTagComponent
                    mainTag={mainTag.id}
                    refresh={() => fetchMainTags()}
                  />
                </div>
              ))}
              <div>
                <input
                  onChange={event => setInput("name", event.target.value)}
                  value={formState.name}
                  placeholder="Enter New Main Tag"
                />
                <button onClick={() => addMainTag()}>Add a new main Tag</button>
              </div>
            </section>
          </div>
        )
      } else {
        return <div>No tags</div>
      }
    } else return <div>You don't have permission to view this page. </div>
  }

  return (
    <section>
      <div className="authenticator">
        <Authenticator
          hideDefault={true}
          onStateChange={handleAuthStateChange()}
        >
          <MainTagList />

          <SignIn />
          {/* <CustomSignUp /> */}
          {/* <SignUp signUpConfig={mySignUpConfig} /> */}
          <CustomSignUp />
          <ConfirmSignUp />
          <ForgotPassword />
          <SignupLink />
        </Authenticator>
      </div>
      <SEO title="Admin Dashboard | Tag Editor" />
      <Link to="/admin">Go back to Admin dashboard</Link>
    </section>
  )
}

export default TagsEditorPage
